import React, { useEffect, useState } from "react";
import "../styles/App.css";

function App() {
  const newUrl = "https://kat.itslanguage.nl";
  const [remainingTime, setRemainingTime] = useState(20);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    if (remainingTime === 0) {
      window.location.href = newUrl;
    }

    return () => clearTimeout(timer);
  }, [remainingTime, newUrl]);

  return (
    <div class="container p-0 justify-content-center text-center">
      <div class="row">
        <div class="col-md-12">
          <h1>
            De KAT is nu te gebruiken op{" "}
            <a href={newUrl} target="_blank" rel="noopener noreferrer">
              kat.itslanguage.nl
            </a>
          </h1>
          <h4>
            Deze website (aurisaaa.nl) zal na 1-1-2025 offline worden gehaald.
          </h4>
          <p className="mt-4" style={{ color: "#7E7D7D" }}>
            Automatisch naar de nieuwe website over {remainingTime} seconden
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
